'use client'
import { usePathname } from '@i18n/navigation'
import Breadcrumbs from '@components/breadcrumbs'
import type { TypeBreadcrumbBlockFields } from '@lib/generated-types'
import { routeMapper, menuItemMapper } from '@lib/mappers/menu-item-mapper'
import { Button } from '@shc/ui'

const BreadcrumbBlock = ({
  breadcrumbs,
  buttonLabel,
  buttonAnchorOrExternalLink,
  buttonLink,
}: TypeBreadcrumbBlockFields) => {
  const link = routeMapper(buttonLink, buttonAnchorOrExternalLink)
  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs?.map(menuItemMapper) ?? []}
      activePathname={usePathname()}>
      {buttonLabel && link.route && (
        <Button
          href={link.route}
          target={link.isInternal ? undefined : '_blank'}
          rel={link.isInternal ? undefined : 'noopener noreferrer'}
          variant="outlined"
          width="auto"
          size="xs">
          {buttonLabel}
        </Button>
      )}
    </Breadcrumbs>
  )
}

export default BreadcrumbBlock
